exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cms-ecommerce-jsx": () => import("./../../../src/pages/cms-ecommerce.jsx" /* webpackChunkName: "component---src-pages-cms-ecommerce-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-designs-jsx": () => import("./../../../src/pages/designs.jsx" /* webpackChunkName: "component---src-pages-designs-jsx" */),
  "component---src-pages-html-jsx": () => import("./../../../src/pages/html.jsx" /* webpackChunkName: "component---src-pages-html-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mob-app-jsx": () => import("./../../../src/pages/mob-app.jsx" /* webpackChunkName: "component---src-pages-mob-app-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../../../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-quote-jsx": () => import("./../../../src/pages/quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */),
  "component---src-pages-samples-jsx": () => import("./../../../src/pages/samples.jsx" /* webpackChunkName: "component---src-pages-samples-jsx" */),
  "component---src-pages-seo-jsx": () => import("./../../../src/pages/seo.jsx" /* webpackChunkName: "component---src-pages-seo-jsx" */),
  "component---src-pages-technologies-jsx": () => import("./../../../src/pages/technologies.jsx" /* webpackChunkName: "component---src-pages-technologies-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-using-ssr-jsx": () => import("./../../../src/pages/using-ssr.jsx" /* webpackChunkName: "component---src-pages-using-ssr-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-app-jsx": () => import("./../../../src/pages/web-app.jsx" /* webpackChunkName: "component---src-pages-web-app-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

